import styled from 'styled-components';
// import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;
  justify-content: center;
  padding: 10px;
  margin-bottom: 30px;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const Content = styled.div`
  width: 100%;
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: left;
  word-wrap: break-word;
  transition: all 1.5s;
  transform: translateY(-2em);
  opacity: 0;
`;
