import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 10px;
`;

export const ProjectsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  grid-gap: 20px;
  position: relative;
  width: 100%;
  justify-items: center;
  ${media.greaterThan('medium')`
    padding:40px 0 0;
  `}
  ${media.greaterThan('large')`
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 auto;
  `}
`;
