import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  overflow: hidden;
  justify-content: center;
  padding: 5px;
  ${media.greaterThan('medium')`
   padding: 10px;
  `}
`;

export const Row = styled.div`
  width: 98%;
  margin: 10px 5px;
  display: flex;
  &:nth-child(2n) {
    flex-direction: row-reverse;
    align-self: flex-end;
    text-align: right;
  }
  ${media.greaterThan('medium')`
    width: 90%;
    margin: 10px;
  `}
  ${media.greaterThan('large')`
    width: 70%;
    margin: 10px 20px;
  `}
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-self: flex-start;
`;

export const Title = styled.h2`
  line-height: 26px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--primary-color);
`;

export const Content = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--primary-color);
  word-wrap: break-word;
`;

export const NumberElement = styled.div`
  font-family: var(--font-anton);
  line-height: 100px;
  font-size: 100px;
  font-weight: 600;
  color: var(--thirdy-color);
  word-wrap: break-word;
  flex-basis: 12%;
  margin: 0 10px;
  ${media.greaterThan('medium')`
     /* margin: 10px 20px; */
  `}
`;
