import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import * as S from './styled';

const WebsiteFeatureItem = ({ title, description, image }) => {
  const img = getImage(image);
  return (
    <S.Container>
      <S.ImageContainer>
        <GatsbyImage
          image={img}
          alt={title}
          object-fit="contain"
        />
      </S.ImageContainer>
      <S.Title>{title}</S.Title>
      <S.Content>{description}</S.Content>
    </S.Container>
  );
};

export default WebsiteFeatureItem;
