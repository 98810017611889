import styled from 'styled-components';
// import media from 'styled-media-query';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

export const Title = styled.h2`
  width: 100%;
  line-height: 26px;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: center;
`;

export const Content = styled.div`
  width: 100%;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  color: var(--primary-color);
  text-align: center;
  word-wrap: break-word;
`;

export const ImageContainer = styled.div`
  padding: 10px;
  align-items: center;
  justify-content: center;
`;
