import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  margin: 40px 0 60px;
  display: inline-block;
`;

export const Title = styled.div`
  position: relative;
  display: inline-block;
`;

export const TitleElement = styled.h3`
  line-height: 140%;
  font-size: 3rem;
  font-weight: 600;
  color: var(--text-light);
  clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  transition: all 1.5s;
  transform: translateY(-1.5em);
  /* ${media.greaterThan('medium')`
    line-height: 1.1;
    font-size: 3.5rem;
  `}

  ${media.greaterThan('large')`
    line-height: 1.1;
    font-size: 3rem;
  `} */
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  z-index: -1;
  svg {
    opacity: 0;
  }
`;
