import styled from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  position: relative;
  width: 100%;
  ${media.greaterThan('medium')`
 grid-template-columns: 1fr 1fr 1fr;
    `}
  ${media.greaterThan('large')`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  `}
`;
