import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import WrapperPage from '../../components/WrapperPage';
import PageImage from '../../components/PageImage';
import WrapperSection from '../../components/WrapperSection';
import TitlePage from '../../components/TitlePage';
import WebsiteProjects from '../../components/WebsiteProjects';
import WebsiteFeatures from '../../components/WebsiteFeatures';

import * as S from './styled';
import WebsiteIntro from '../../components/WebsiteInro';
import WebsiteWorkProcess from '../../components/WebsiteWorkProcess';

const Website = ({ data }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const { items, process, intro } = data.file.childMdx.frontmatter;
  const { nodes: projects } = data.allFile;
  const projectsWithSlug = projects.map(({ childMdx: project }) => ({
    ...project,
    fields: {
      ...project.fields,
      slug: `/strony-internetowe${project.fields.slug}`,
    },
  }));

  return (
    <Layout>
      <WrapperPage id="websites">
        <PageImage>
          <StaticImage
            src="../../assets/images/project.jpg"
            alt="Protokół https"
            style={{ width: '100%', height: '100%', opacity: 0.2 }}
            layout="fullWidth"
            objectPosition="bottom"
            quality={85}
            imgStyle={{ filter: 'grayscale()' }}
          />
        </PageImage>
        <S.Wrapper>
          <WrapperSection>
            <S.Container>
              <TitlePage
                text={t('websitesTitle')}
                subtitle={t('websitesSubtitle')}
              />
              <WebsiteIntro intro={intro} />
              <WebsiteFeatures features={items} />
              <WebsiteWorkProcess process={process} />
              <WebsiteProjects projects={projectsWithSlug} />
            </S.Container>
          </WrapperSection>
        </S.Wrapper>
      </WrapperPage>
    </Layout>
  );
};

export default Website;

export const Head = ({ data }) => (
  <SEO
    title={`${data.file.childMdx.frontmatter.title} - Giedeé Informatyka`}
  />
);

export const query = graphql`
  query Websites($locale: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "projects" }
        childMdx: { fields: { locale: { eq: $locale } } }
      }
      sort: { fields: childrenMdx___frontmatter___date, order: DESC }
    ) {
      nodes {
        childMdx {
          id
          fields {
            slug
          }
          frontmatter {
            description
            title
            heading
            subtitle
            featuredimage {
              alt
              src {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    quality: 85
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
    file(
      extension: { eq: "mdx" }
      relativeDirectory: { eq: "website-features" }
      childMdx: { fields: { locale: { eq: $locale } } }
    ) {
      childMdx {
        id
        frontmatter {
          title
          subtitle
          intro
          language
          items {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  height: 60
                  quality: 85
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          process {
            title
            content
          }
        }
      }
    }
  }
`;
