import React, { useEffect, useRef } from 'react';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import TitleSection from '../TitleSection';

const WebsiteWorkProcess = ({ process }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const stepsCards = useRef([]);
  stepsCards.current = [];

  const addToRefs = (el) => {
    if (el && !stepsCards.current.includes(el)) {
      stepsCards.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(stepsCards.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(stepsCards.current, {
      start: 'top bottom-=100px',
      // markers: true,
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 1,
          autoAlpha: 1,
          scale: 1,
          ease: Power4.easeOut,
          stagger: 0.3,
          delay: 0.5,
        }),
    });
  }, []);
  return (
    <S.Wrapper id="WebsiteWorkProcess">
      <TitleSection title={t('websiteProccessTitle')} />
      <S.Container>
        {process &&
          process.map((item, index) => (
            <S.Row ref={addToRefs} key={item.title}>
              <S.NumberElement>{index + 1}</S.NumberElement>
              <S.Container>
                <S.Title>{item.title}</S.Title>
                <S.Content>{item.content}</S.Content>
              </S.Container>
            </S.Row>
          ))}
      </S.Container>
    </S.Wrapper>
  );
};

export default WebsiteWorkProcess;
