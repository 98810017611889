import React, { useEffect, useRef } from 'react';
import { gsap, Power4 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useTranslation } from 'react-i18next';
import WebsiteFeatureItem from '../WebsiteFeatureItem';
import TitleSection from '../TitleSection';

import * as S from './styled';

const WebsiteFeatures = ({ features }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  const featuresCards = useRef([]);
  featuresCards.current = [];

  const addToRefs = (el) => {
    if (el && !featuresCards.current.includes(el)) {
      featuresCards.current.push(el);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    gsap.set(featuresCards.current, { autoAlpha: 0, scale: 0.9 });
    ScrollTrigger.batch(featuresCards.current, {
      start: 'top bottom-=100px',
      // markers: true,
      onEnter: (batch) =>
        gsap.to(batch, {
          duration: 1,
          autoAlpha: 1,
          scale: 1,
          ease: Power4.easeOut,
          stagger: 0.3,
          delay: 0.5,
        }),
    });
  }, []);

  return (
    <S.Wrapper id="WebsiteFeatures">
      <TitleSection
        title={t('websiteFeaturesTitle')}
        gsapDelay={1.1}
      />
      <S.Container>
        {features.map((item) => (
          <div ref={addToRefs} key={item.title}>
            <WebsiteFeatureItem
              key={item.title}
              title={item.title}
              description={item.description}
              image={item.image}
            />
          </div>
        ))}
      </S.Container>
    </S.Wrapper>
  );
};

export default WebsiteFeatures;
