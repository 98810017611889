import gsap, { Power1 } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useEffect, useRef } from 'react';

import * as S from './styled';

const TitleSection = ({ title, gsapDelay = 0 }) => {
  gsap.registerPlugin(ScrollTrigger);
  let svgPath = useRef(null);
  let svgTag = useRef(null);
  let box = useRef(null);
  let mask = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: gsapDelay, ease: Power1.easeInOut },
      scrollTrigger: {
        trigger: box,
        start: '-=200 bottom-=100px',
        end: '+=500',
        // markers: true,
      },
    });

    tl.to(svgTag, { delay: gsapDelay, opacity: 1 })
      .to(svgPath, {
        strokeDashoffset: 0,
        duration: 2,
      })
      .to(
        mask,
        {
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)',
          y: 0,
          duration: 1,
        },
        '-=2.5'
      )
      .to(svgPath, { attr: { x2: '93' } });
      
  }, []);

  return (
    <S.Container
      className="container"
      ref={(el) => {
        box = el;
      }}
    >
      <S.Title>
        <S.TitleElement
          ref={(el) => {
            mask = el;
          }}
        >
          {title}
        </S.TitleElement>

        <S.ImageContainer>
          <svg
            width="93"
            height="3"
            viewBox="0 0 93 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ref={(el) => {
              svgTag = el;
            }}
          >
            <line
              y1="1.5"
              x2="0"
              y2="1.5"
              stroke="#393F4D"
              strokeWidth="3"
              fillOpacity="0"
              ref={(el) => {
                svgPath = el;
              }}
            />
          </svg>
        </S.ImageContainer>
      </S.Title>
    </S.Container>
  );
};

export default TitleSection;
