import React, { useEffect, useRef } from 'react';
import gsap, { Circ } from 'gsap';
import { useTranslation } from 'react-i18next';
import TitleSection from '../TitleSection';

import * as S from './styled';

const WebsiteIntro = ({ intro }) => {
  const { t } = useTranslation('translation', {
    useSuspense: false,
  });
  let box = useRef(null);

  useEffect(() => {
    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.5, ease: Circ.easeInOut },
    });

    tl.to(
      box,
      {
        y: 0,
        opacity: 1,
        delay: 2,
        duration: 1,
      }
      // '-=2.5'
    ).play();
  }, []);

  return (
    <S.Wrapper id="WebsiteIntro">
      <TitleSection title={t('websiteIntroTitle')} gsapDelay={0.8} />
      <S.Container>
        <S.Content
          ref={(el) => {
            box = el;
          }}
        >
          {intro}
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};
export default WebsiteIntro;
